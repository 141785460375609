import { appProvider } from '@/app-provider'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { action, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { walletStore } from './wallet-store'

export class UserProfileStore {
  @observable profile: ProfileModel
  @observable followLoading = false
  @observable isFollowing = false
  constructor(profile) {
    this.profile = profile
    this.isFollowing = profile.isFollowing ? true : false
  }

  @asyncAction *followUserProfile() {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.followLoading = true
      yield apiService.userFollows.followUser({ follow: this.profile._id })
      this.isFollowing = true
    } catch (error) {
    } finally {
      this.followLoading = false
    }
  }

  @asyncAction *unFollowUserProfile() {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.followLoading = true
      yield apiService.userFollows.unFollowUser({ follow: this.profile._id })
      this.isFollowing = false
    } catch (error) {
    } finally {
      this.followLoading = false
    }
  }

  @action goToProfilePage() {
    const userId = this.getProfileId(this.profile)
    appProvider.router.push(`/profile/${userId}/summary`)
  }

  getProfileId(profile: ProfileModel) {
    return profile.unique_id
  }
}
