import { apiService } from '@/services/api-services'
import { action, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { debounce } from 'lodash'
import { appProvider } from '@/app-provider'
import { localData } from '@/stores/local-data'
import { PostStore } from '@/stores/post-store'

export class SearchController {
  @observable searchInput = ''
  @observable searchOptions: string[] = []

  @observable loadingTrending = false
  @observable trendingPosts: PostStore[] = []

  @observable lastSearchs: string[] = localData.lastSearchs

  @observable loadCount = 0

  constructor() {
    this.getSearchOption()
    this.fetchTrending()
    let last: PostStore[] = []
    reaction(() => this.trendingPosts, (cur) => {
      cur = cur || []
      const removes = last.filter(x => !cur.includes(x))
      removes.forEach(e => e.destroy());
      last = cur
    })
  }

  @asyncAction *fetchTrending() {
    try {
      this.loadingTrending = true
      const res = yield apiService.posts.fetchPosts({ _sort: 'topScore:desc', _start: this.loadCount, _limit: 3, type: 'everyone' })
      this.loadCount += 3
      this.trendingPosts = res.map(x => new PostStore(x))
    } finally {
      this.loadingTrending = false
    }
  }

  @asyncAction *getSearchOption() {
    const res = yield apiService.tags.find({ _limit: -1 })
    if (res) this.searchOptions = res.map((item) => item.content)
  }

  @action.bound removeSearchHistory(item) {
    this.lastSearchs = localData.removeLastSearch(item)
  }

  @action.bound onSearch(input: string) {
    this.searchInput = input
    const searchTerm = this.searchInput.replaceAll('#', '')
    this.lastSearchs = localData.addLastSearch(searchTerm)
    if (appProvider.router.currentRoute.path === '/search/forum') {
      appProvider.router.replace(`/search/forum?text=${searchTerm}`)
    } else {
      appProvider.router.push(`/search/forum?text=${searchTerm}`)
    }
  }
}

export const searchController = new SearchController()
