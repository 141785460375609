




























































































import { searchController } from '@/components/search/search.controller'
import { promiseHelper } from '@/helper/promise-helper'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { SearchViewModel } from '../viewmodels/search-viewmodel'

@Observer
@Component({
  components: {
    'all-search': () => import('@/modules/search/components/containers/all-search.vue'),
    users: () => import('@/modules/search/components/containers/users.vue'),
    posts: () => import('@/modules/search/components/containers/posts.vue'),
    daos: () => import('@/modules/search/components/containers/daos.vue'),
    tags: () => import('@/modules/search/components/containers/tags.vue'),
    search: () => import('@/components/search/search.vue'),

    'most-active-user-section': () => import('@/modules/common/components/section/most-active-user-section.vue'),
    'most-active-dao-section': () => import('@/modules/common/components/section/most-active-dao-section.vue'),
  },
})
export default class SearchPage extends Vue {
  @Provide() vm = new SearchViewModel()
  searchController = searchController
  @Ref('searchInput') searchInput

  async mounted() {
    await promiseHelper.delay(200)
    this.searchInput && this.searchInput.focus()
  }

  createPostController = createPostController

  @Watch('$route', { immediate: true }) queryChange(value) {
    if (this.$router.currentRoute.query.text) {
      this.vm.init()
    }
  }

  goBack() {
    history.back()
  }

  destroyed() {
    this.vm.destroy()
  }
}
