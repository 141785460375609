import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync } from '@/helper/utils'
import { TagModel } from '@/models/post-model'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, FollowTypeEnum } from '@/stores/dispatcher'
import { PostStore } from '@/stores/post-store'
import { UserProfileStore } from '@/stores/userProfileStore'
import { walletStore } from '@/stores/wallet-store'
import { action, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class SearchViewModel {
  @observable display: 'grid' | 'list' = 'grid'
  @observable searchTerm = ''
  @observable tagMatched?: TagModel = undefined
  @observable tags: TagModel[] = []
  @observable tagLoading = false
  @observable relatedTagsLoading = false
  @observable daoLoading = false
  @observable daos: any[] = []
  @observable daoShowAll = false

  @observable posts: PostStore[] = []
  @observable postLoading = false

  @observable reviews: PostStore[] = []
  @observable reviewLoading = false
  @observable reviewShowAll = false

  @observable users: UserProfileStore[] = []
  @observable userLoading = false
  @observable userFilter = { page: 0, pageSize: 20 }
  @observable userShowAll = false

  @observable tabs = [
    { index: 1, title: 'All', icon: 'close.svg' },
    { index: 2, title: 'Posts', icon: 'close.svg' },
    { index: 3, title: 'DAOs', icon: 'close.svg' },
    { index: 4, title: 'Users', icon: 'close.svg' },
    { index: 5, title: 'Tags', icon: 'close.svg' },
  ]

  @observable tab = this.tabs[0].index

  private _unsubcrible = new Subject()
  private _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      reaction(
        () => createPostController.completedUpdateType,
        (type) => {
          if (type) {
            createPostController.changeCompletedUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
      reaction(
        () => postController.completeUpdateType,
        (type) => {
          if (type) {
            postController.changeCompleteUpdateType(undefined)
            this.fetchPosts()
          }
        }
      ),
    ]

    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
    // this.init()
  }

  @action.bound showAllUsers(value: boolean) {
    this.userShowAll = value
  }

  @action.bound showAllDaos(value: boolean) {
    this.daoShowAll = value
  }

  @action.bound showAllReviews(value: boolean) {
    this.reviewShowAll = value
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (profile.type === FollowTypeEnum.tag && this.tagMatched?.id === profile.to) {
      this.tagMatched = {
        ...this.tagMatched,
        isFollowing: profile.followState,
        totalFollowers: getFollowStatsNumberAfterSync(this.tagMatched.totalFollowers, profile.followState, 0),
        loading: false,
      }
      return
    }
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
    this.posts.forEach((postStore) => postStore.destroy())
    this.reviews.forEach((postStore) => postStore.destroy())
    this._disposers.forEach((d) => d())
  }

  @action.bound init() {
    const searchTerm = appProvider.router.currentRoute.query.text as string
    if (searchTerm) {
      this.searchTerm = searchTerm
      this.fetchTags()
      this.fetchDaos()
      this.fetchPosts()
      this.fetchRelatedTags()
      this.fetchRelatedReview()
      this.fetchUserSearched()
    }
  }

  @action.bound changeDisplay(value: 'list' | 'grid') {
    this.display = value
  }

  @asyncAction *fetchPosts() {
    try {
      this.postLoading = true
      const res = yield apiService.posts.searchPosts(this.searchTerm)
      if (res) {
        this.posts = res.map((post) => new PostStore(post))
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.postLoading = false
    }
  }

  @asyncAction *fetchTags() {
    try {
      this.tagLoading = true
      const res = yield apiService.tags.find({ content: `#${this.searchTerm.toLowerCase()}` })
      if (res && res[0]) {
        this.tagMatched = { ...res[0], isFollowing: false }

        if (res[0]._id && walletStore.userProfile?._id) {
          const tagFollowings = yield apiService.tagFollows.find({
            follower: walletStore.userProfile?._id,
            tag: res[0]._id,
          })
          if (tagFollowings.length) this.tagMatched = { ...res[0], isFollowing: true }
        }
      } else {
        this.tagMatched = undefined
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.tagLoading = false
    }
  }

  @asyncAction *fetchRelatedTags() {
    try {
      this.relatedTagsLoading = true
      const res = yield apiService.tags.searchForum(this.searchTerm)
      if (res) {
        this.tags = res.filter((item) => item.content !== `#${this.searchTerm}`)
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.relatedTagsLoading = false
    }
  }

  @asyncAction *fetchDaos() {
    try {
      this.daoLoading = true
      const res = yield apiService.daos.searchDao(this.searchTerm)
      if (res) {
        this.daos = res
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.daoLoading = false
    }
  }

  @asyncAction *fetchRelatedReview() {
    try {
      this.reviewLoading = true
      const res = yield apiService.posts.searchReviews(this.searchTerm)
      if (res) {
        this.reviews = res.map((post) => new PostStore(post))
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.reviewLoading = false
    }
  }

  @asyncAction *fetchUserSearched() {
    try {
      this.userLoading = true
      const res = yield apiService.profiles.searchUsers({ page: 0, pageSize: 20, text: this.searchTerm })
      if (res.data) {
        const profiles = res.data.map((profile) => new UserProfileStore(profile))
        this.users = profiles
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.userLoading = false
    }
  }

  @asyncAction *followTag(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.tagFollows.followTag({ tag: item._id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowTag(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.tagFollows.unFollowTag({ tag: item._id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *joinDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.daos.joinDao({ daoId: item.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *leaveDao(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.daos.leaveDao({ daoId: item.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @computed get usersSearch() {
    if(this.userShowAll) return this.users
    else return this.users.slice(0, 6)
  }

  @computed get showAllUserDisplayed() {
    return this.users.length > 6 && this.userShowAll === false
  }

  @computed get showLessUserDisplayed() {
    return this.users.length > 6 && this.userShowAll === true
  }

  @computed get daosSearch() {
    if(this.daoShowAll) return this.daos
    else return this.daos.slice(0, 6)
  }

  @computed get showAllDaoDisplayed() {
    return this.daos.length > 6 && this.daoShowAll === false
  }

  @computed get showLessDaoDisplayed() {
    return this.daos.length > 6 && this.daoShowAll === true
  }

  @computed get reviewsSearch() {
    if(this.reviewShowAll) return this.reviews
    else return this.reviews.slice(0, 6)
  }

  @computed get showAllReviewsDisplayed() {
    return this.reviews.length > 6 && this.reviewShowAll === false
  }

  @computed get showLessReviewsDisplayed() {
    return this.reviews.length > 6 && this.reviewShowAll === true
  }
}
